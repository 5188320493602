<template>
    <div>
        <!-- <Topbar /> -->
        <Navbar />
        <!-- <PageBanner pageTitle="Payments" className="page-banner-area bg-2" /> -->
        <div class="about-area">
            <div class="container">
                <div class="row ptb-100">
                    <div class="py-50" id="payment-response">
                        {{ response }}
                    </div>
                    <div class="col-sm-12">
                        <div class="cart-totals border-success">
                            <h1 class="text-success">Payment Success</h1>
                            <div class="py-2">
                                <i class="ri-checkbox-circle-line text-success" style="font-size: 4em;"></i>
                            </div>   
                            <div><router-link to="/payments" class="nav-link text-primary">Back to Payments</router-link></div>                         
                        </div>
                    </div>
                </div>
            </div>        
        </div>        
        <Footer class="margin-zero" /> 
    </div>
</template>
<style>
.w-250px{
    width:250px !important;
}
.ptb-50{ 
    padding-top: 50px !important;
}
.courses-details-desc .courses h3 {
    padding-bottom: 10px;
    border-bottom: 4px solid #feb302;
    margin-bottom: 25px;
}
.emi-table td{
    vertical-align:middle;
}
</style>
<script>
// import { instance } from '../../api'; 
// import Topbar from '../Layouts/Topbar'
import Navbar from '../Layouts/Navbar'
// import PageBanner from '../Common/PageBanner'
import Footer from '../Layouts/Footer'
export default {
    name: 'ShopPage',
    components: {
        //Topbar,
        Navbar,
        // PageBanner,
        Footer,
    },
    data: function() {
		return {
            response : ""
		};
	},
    mounted: function() {        
        this.callPaymentResponse();	
	},
    methods: {
        callPaymentResponse: function(){
            this.response = localStorage.getItem('payment_response');
        }
    }
}
</script>