<template>
    <!-- <Preloader v-if="isLoading" /> -->
    <div v-if="shown" class="d-flex text-center justify-content-center align-items-center flex-wrap fs-6 bg-light-primary py-1 gap-1 px-1">
        Install URUDI App to your Home Screen to enjoy a unique and native experience.
        <button @click="installPWA" class="btn btn-sm btn-success">
            Install!
        </button>
        <button @click="dismissPrompt" class="mx-2 btn btn-sm btn-danger">
            No, thanks
        </button>
    </div>
    <router-view />
    <BackToTop />
</template>

<script>
// import Preloader from './components/Layouts/Preloader'
import BackToTop from './components/Layouts/BackToTop'

export default {
    name: 'App',
    components: {
        // Preloader,
        BackToTop,
    },
    data() {
        return {
            shown: false,
            // isLoading: true
        }
    },
    beforeMount() {
        window.addEventListener('beforeinstallprompt', (e) => { 
            e.preventDefault()
            this.installEvent = e
            //Setting Timeout Before Prompt Shows Again if Dismissed
            var pwaRemind = 1;
            var pwaName   = "URUDI-APP"; 
            var hours = pwaRemind * 24; // Reset when storage is more than 24hours
            var now = Date.now();
            var setupTime = localStorage.getItem(pwaName+'-PWA-Timeout-Value');
            if (setupTime == null) {
                localStorage.setItem(pwaName+'-PWA-Timeout-Value', now);
            } else if (now - setupTime > hours*60*60*1000) {
                localStorage.removeItem(pwaName+'-PWA-Prompt');
                localStorage.setItem(pwaName+'-PWA-Timeout-Value', now);
            }
            if (localStorage.getItem(pwaName+'-PWA-Prompt') != "install-rejected") {
                this.shown = true
            }else{
                this.shown = false
            }
        })
    },
    methods: {
        dismissPrompt() {
            var now     = Date.now();
            var pwaName = "URUDI-APP";
            this.shown  = false;
            localStorage.setItem(pwaName+'-PWA-Timeout-Value', now);
            localStorage.setItem(pwaName+'-PWA-Prompt', 'install-rejected');
        },
        installPWA() {
            this.installEvent.prompt()
            this.installEvent.userChoice.then((choice) => {
                this.dismissPrompt() // Hide the prompt once the user's clicked
                if (choice.outcome === 'accepted') {
                    // Do something additional if the user chose to install
                } else {
                    // Do something additional if the user declined
                    // var now     = Date.now();
                    // var pwaName = "URUDI-APP";
                    // this.shown  = false;
                    // localStorage.setItem(pwaName+'-PWA-Timeout-Value', now);
                    // localStorage.setItem(pwaName+'-PWA-Prompt', 'install-rejected');
                }
            })
        },
    }
    // mounted() {
    //     setTimeout(() => {
    //         this.isLoading = false
    //     }, 2000)
    // }
}
</script>