<template>
    <div>
        <!-- <Topbar /> -->
        <Navbar />
        <PageBanner pageTitle="Services" className="page-banner-area bg-2" />
        <div class="about-area ptb-100">
            <div class="container">
                <div class="row align-items-start pb-75">
                    <div class="col-lg-6 col-md-12">
                        <div class="about-wrap-image" data-tilt>
                            <img src="../../assets/images/about/about-3.jpg" alt="image" data-aos="fade-down" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="about-wrap-content mt-0" data-aos="fade-up" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                            <!-- <div class="about-bg-text">ABOUT US</div> -->
                            <span>WHO WE ARE</span>
                            <h3>Your Trusted Partner For All IT Solutions</h3>
                            <p>We are leading technology solutions providing company all over the world doing over 40 years. Lorem ipsum dolor sit amet consetetur sadipscing elitre sed diam non umy eirmod tempor invidunt ut labore.</p>
                        </div>
                    </div>
                </div>
                <div class="row align-items-start">
                    <div class="col-lg-6 col-md-12">
                        <div class="about-wrap-content mt-0" data-aos="fade-up" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                            <!-- <div class="about-bg-text">ABOUT US</div> -->
                            <span>WHO WE ARE</span>
                            <h3>Your Trusted Partner For All IT Solutions</h3>
                            <p>We are leading technology solutions providing company all over the world doing over 40 years. Lorem ipsum dolor sit amet consetetur sadipscing elitre sed diam non umy eirmod tempor invidunt ut labore.</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="about-wrap-image" data-tilt>
                            <img src="../../assets/images/about/about-3.jpg" alt="image" data-aos="fade-down" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                        </div>
                    </div>
                </div>
            </div>        
        </div>        
        <Footer class="margin-zero" />
    </div>
</template>

<script>
// import Topbar from '../Layouts/Topbar'
import Navbar from '../Layouts/Navbar'
import PageBanner from '../Common/PageBanner'
import Footer from '../Layouts/Footer'

export default {
    name: 'ShopPage',
    components: {
        // Topbar,
        Navbar,
        PageBanner,
        Footer,
    },
}
</script>