import axios from 'axios';
export const BasePath   =   "https://www.urudifinance.com/api/v1/";
export const AdminPath  =   "https://www.urudifinance.com/";  
export const UserPath   =   "https://www.urudi.org/";  
const ApiToken          =   "1|XS0uy37GYKu0R2r8IsLgkXA8SaqnSJYAj8JHa155"; //live
// const ApiToken          =   "1|iVKsGR1YS38mRoIFwoy1yfg3zD1NtFLB9MDmRNkx";
export const instance   =   axios.create({ 
                                headers: {
                                    'Authorization': 'Bearer '+ApiToken
                                }
                            }); 