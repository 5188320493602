<template>
    <div>
        <!-- <Topbar /> -->
        <Navbar />
        <PageBanner pageTitle="Terms And Conditions" className="page-banner-area bg-2" />
        <div class="about-area pt-100 pb-75">
            <div class="container">
                <p>
                    We at Universal Rural And Urban Development Initiatives ("our","us", "we", "Company","URUDI") have developed the URUDI application ("App") and are happy to have you with us. 
                    Please read these terms of use ("Terms") carefully before you use the App since they constitute the agreement between: (a) you;(b) us.
                </p>
                <p>
                    For your ease, we have provided summaries of some crucial Terms. However, in case of any conflict between the summaries and these Terms, the Terms shall prevail.
                </p>
                <p>
                    These Terms incorporate the following documents (including all their variants and updates) by reference: (a) the URUDI Privacy Policy; and (b) the agreement(s) 
                    executed by you (and co-applicants, members, nominees, if any) for availing the Services (as defined in the Sections below) from us.
                </p>
                <p>
                    All our Services are subject to applicable laws, government notifications, the rules, regulations and guidelines issued by the Reserve Bank of India ("RBI"), 
                    the Securities and Exchange Board of India ("SEBI"), and Insurance Regulatory and Development Authority ("IRDAI"), and any other regulatory bodies defining 
                    the rules/regulations governing the offer of any Services on the App.
                </p>
                <p>
                    We may revise these Terms as well as update the App and Services from time to time, so please keep visiting this page regularly. 
                    If you do not agree with any part of these Terms, please stop using the App immediately. If you have any queries or concerns regarding these Terms,
                     please contact our Grievance Officers.
                </p>
                <h4>PRIVACY OF DATA</h4>
                <p>
                    
                    The URUDI Privacy Policy provides the manner in which we process, collect, use and share the data that is collected from you. 
                    Please read it so you know your rights in this regard.
                </p>
                <h4> YOUR OBLIGATIONS</h4>
                <p>
                    <ul>
                        <li> 
                            Duty to provide true information: The information you provide is used by us to determine your eligibility for our Services. 
                            It is critical that all information you provide to us is true, complete, not misleading and is regularly updated by you. 
                            If all or any part of this information is incorrect, incomplete, fraudulent or misleading, it would be a breach of these Terms and a violation of the law.
                             You must further ensure that you do not do anything that can make the information provided by you incorrect, incomplete, 
                             fraudulent or misleading at a later date. If you discover any information provided is incorrect, incomplete, fraudulent or misleading, 
                             then please write to the respective Grievance Officer immediately.
                        </li>
                        <li> 
                            Duty to be responsible: Considering the nature of the App and Services provided, please ensure that you keep your mobile device safe. 
                            You are solely responsible for all activities that occur under your credentials on the App and any amounts debited or credited to your bank account. 
                            You should keep your password safe and not disclose your URUDI Account details to any third party or share the account with any third party. 
                            If you think someone has gained access to your URUDI Account, please contact our Grievance Officer immediately and undertake such other activities as 
                            may be required. If you are directed to our third-party services, you may be subject to terms and conditions governing use of that third party's service 
                            and that third party's personal information collection practices. Please review such terms and conditions and privacy policy before using 
                            the third party's services.
                        </li>
                        <li> 
                            Obligation to indemnify: To the extent permitted under applicable law, you agree, to indemnify us (i.e., the Company), our directors, agents, 
                            and employees from and against all complaints, demands, claims, damages, losses, costs, liabilities and expenses, including attorney’s fees, due to, 
                            arising out of or relating in any way to (a) your access to or use of the App and/or Services; (b) your breach of these Terms; (c) your breach of any 
                            applicable laws or third-party rights; and/ or (d) any act of fraud or misrepresentation.
                        </li>
                        <li> 
                            Ensure compatibility and keep updated: You must ensure that you keep updating the App as and when we release new versions of it. 
                            Failure to do so may make you incapable of using certain Services or the App altogether. You should also ensure that you are able to use 
                            the App with your preferred bank account.
                        </li>
                    </ul>
                </p>
                <h4>RESTRICTIONS ON YOU</h4>
                <p>
                    <ul>
                        <li> 
                             No scaling or jeopardizing our platform: You agree to not interfere with or use non-public areas of our App and our technical delivery system. 
                             You will not introduce any trojans, viruses, any other malicious software, any bots or scrape our App for any user information. Additionally, 
                             you will not probe, scan, or test the vulnerability of any system, security or authentication measures implemented by us. 
                             If you tamper or attempt to tamper with our technological design and architecture, we may terminate your URUDI Account. We may further report 
                             such actions to the appropriate law enforcement authorities and initiate legal actions.
                        </li>
                        <li>
                            No commercial usage: You shall use the Services only for your lawful and personal use. You will not use our Services for any purposes not 
                            permitted under applicable law and these Terms.
                        </li>
                        <li>
                            No illegal usage: You shall not use the App or the Services for committing fraud, embezzlement, money laundering or for any other unlawful and/or 
                            illegal purposes. Further, although we may, from time to time, monitor or review any facilities, if established or otherwise offered on the App for 
                            discussions, chats, postings, transmissions, bulletin boards, and the like on the App, you are prohibited from posting or transmitting any unlawful, 
                            threatening, libelous, defamatory, obscene, scandalous, inflammatory, pornographic, profane material or any material that could constitute or encourage 
                            conduct that would be considered a criminal offense, give rise to civil liability, or otherwise violate any law. We are under no obligation to do so and 
                            assume no responsibility or liability arising from the content of any Summary. You shall not hack or in any way jeopardize the App. You should not use 
                            the App for any forward lending purposes or to grant loans to any third parties. You should not use the App/Services for any illegal purposes. 
                            These are explicitly barred. such locations nor for any error, defamation, libel, slander, omission, falsehood, obscenity, pornography, profanity, 
                            danger, or inaccuracy contained in any information contained within such locations on the App. We will fully cooperate with any law enforcement 
                            authorities or court order requesting or directing us to disclose the identity of anyone posting any such information or materials
                        </li>
                        <li>
                            Maintenance of bank accounts: You shall not do any action that may jeopardize the URUDI Account or in any way frustrate the repayment of 
                            the loan amounts as may be disbursed to you.
                        </li>
                    </ul>
                </p>
                <h4>INTELLECTUAL PROPERTY</h4>
                <p>
                    <ul>
                        <li>
                            All of the content on the App, including, all images, illustrations, graphics, video clips, text, reports generated, trademarks, as well as 
                            the underlying code of the App (“App Content”), constitutes our intellectual property.
                        </li>
                        <li>
                            We give you a limited, non-transferrable, non-sub licensable and revocable license to access the App, avail of the features of the App 
                            for your personal, lawful requirements only. You are not entitled to duplicate, distribute, create derivative works of, display, or 
                            commercially exploit the App Content, features or facilities, directly or indirectly, without our prior written permission.
                        </li>
                    </ul>
                </p>
                <h4>REGULATORY CHANGES</h4>
                <p>
                    Regulation around technology companies such as ours is ever changing. You understand that the Company may have to modify the Services as well as the App on 
                    account of the regulatory landscape we are subject to. In such case, if you become incapable of using all or any part the App or the Services, 
                    the same shall not be our fault.
                </p>
                <h4>THIRD PARTY LIABILITY</h4>
                <p>
                    In providing the Services to you, we will need to use third-party services. This is done to facilitate checking your credit score, facilitating payment 
                    from you and other practical and functional purposes in order to enhance the Services we provide you. While we have appropriate agreements in place with 
                    these third parties, we do not accept any liabilities that may arise from our use of or reliance on such third-party services. Further, it may so happen 
                    that you are unable to link your bank account with the App – whether to credit or debit the loan amounts - in which case, we will not be liable for any 
                    damages or losses suffered by you.
                </p>
                <h4>THIRD PARTY CONTENT AND ADVERTISEMENTS</h4>

                <p>
                    We may, from time to time, display offers and advertisements from third parties on our App for your benefit. However, this does not mean we endorse 
                    these third parties. If you accept any of the services of such third parties, such arrangement shall be solely between you and the third party, 
                    you should avail of such services only after you have read their terms of use and privacy policies.
                </p>
                <h4>TERMINATION</h4>
                <p>
                    If we decide to terminate your URUDI Account for any reason, we will inform you of such decision on the App or by SMS/email. However, this will not 
                    extinguish your obligations under the applicable documents/policies. We will not be liable for any losses or damages that you may suffer from such 
                    termination of your URUDI Account.
                </p>
                <h4>FORCE MAJEURE</h4>
                <p>
                    Without limiting the foregoing, under no circumstances shall we be held liable for any damage, loss, loss of services of App, due to deficiency in 
                    provision of the Services resulting directly or indirectly from acts of nature, forces, or causes beyond its reasonable control, including, without 
                    limitation, internet failures, computer equipment failures, telecommunication equipment failures, or any other government regulations, floods, storms, 
                    electrical failure, civil disturbances, riots.
                </p>
                <h4>GENERAL PROVISIONS</h4>
                <ul>
                    <li>
                        Notification: We shall notify you of any relevant information pertaining to your use of the Services by push notification on the App, SMS, call, 
                        WhatsApp, instant messaging services or through any other means that we may deem appropriate. You authorize us to reach out to you, irrespective of 
                        whether you register with the National Do Not Call registry.
                    </li>
                    <li>
                        Disputes: These Terms and any action related thereto will be governed by the laws of India. Any disputes arising out of or related to the Terms, 
                        the App, or the Services shall be subject to the jurisdiction of the courts located in Bengaluru, Karnataka, India.
                    </li>
                    <li>
                        Assignment: You shall not assign or transfer any right or obligation that has accrued to you under these Terms, and any attempt by you to assign 
                        or transfer such rights and obligations, shall be null and void. We may assign or transfer any right or obligations that accrued in our favor, 
                        at our sole discretion, without any restriction.
                    </li>
                    <li>
                        Waiver: Unless otherwise stated expressly, any delay or failure in our exercising any rights/remedies arising out of these Terms and/or 
                        other policies available on the App, shall not constitute a waiver of rights or remedies and no single/partial exercise of any rights or remedies, 
                        hereunder, shall prevent any further exercise of the rights/remedies by us.
                    </li>
                    <li>
                        Survival: You acknowledge that your representations, undertakings, and warranties and the clauses relating to indemnities, limitation of liability, repayment of loan, governing law & arbitration and these general provisions shall survive the efflux of time and the termination of these Terms.
                    </li>
                    <li>
                        Severability: If any provision of these Terms is held illegal or unenforceable, the validity, legality and enforceability of the remaining provisions contained herein shall not in any way be affected or impaired thereby. Any such provision held invalid, illegal or unenforceable shall be substituted by a provision of similar import reflecting the original intent of the parties to the extent permissible under applicable laws.
                    </li>
                    <li>
                        Amendment: We reserve the right to change, modify, add to, or remove discounts, portions of these Terms at any time, subject to the policies of the Company and applicable regulations.
                    </li>
                    <li>
                        Mobile Communication & Consent: I agree and consent to receive all communications at the mobile number provided, even if this mobile number is registered under DND/NCPR list under TRAI regulations. And for that purpose, I further authorize the Company to share/disclose the information to any third-party service provider or any affiliates, group companies, their authorized agents, or third-party service providers.
                    </li>
                </ul>           
            </div>
        </div>
        <Footer class="margin-zero" />
    </div>
</template>

<script>
// import Topbar from '../Layouts/Topbar'
import Navbar from '../Layouts/Navbar'
import PageBanner from '../Common/PageBanner'
import Footer from '../Layouts/Footer'

export default {
    name: 'AboutPageOne',
    components: {
        // Topbar,
        Navbar,
        PageBanner,
        Footer,
    }
}
</script>