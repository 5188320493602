<template>
    <div class="overview-area pt-100 pb-75">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-3 col-md-6">
                    <div class="overview-card">
                        <h3>Call Us</h3>
                        <span>
                            <a href="tel:+918838971512">+918838971512</a> 
                        </span>

                        <div class="overview-shape">
                            <img src="../../assets/images/overview/overview-shape.png" alt="image">
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="overview-card">
                        <h3>Email Us</h3>
                        <span>
                            <a href="mailto:hikoze@gmail.com">hariharan@urudi.org</a>
                        </span>

                        <div class="overview-shape">
                            <img src="../../assets/images/overview/overview-shape.png" alt="image">
                        </div>
                    </div>
                </div>

                <!-- <div class="col-lg-3 col-md-6">
                    <div class="overview-card">
                        <h3>Tech Support</h3>
                        <span>
                            <a href="tel:15143125678">+1 (514) 312-5678</a>
                        </span>

                        <div class="overview-shape">
                            <img src="../../assets/images/overview/overview-shape.png" alt="image">
                        </div>
                    </div>
                </div> -->

                <div class="col-lg-3 col-md-6">
                    <div class="overview-card">
                        <h3>Visit Us</h3>
                        <span>No.3875, Abishek Complex, 1st Floor, Maruthi Nagar, Trichy-621216.</span>

                        <div class="overview-shape">
                            <img src="../../assets/images/overview/overview-shape.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Overview'
}
</script>