<template>
    <div>
        <!-- <Topbar /> -->
        <Navbar />
        <PageBanner pageTitle="Disclaimer Policy" className="page-banner-area bg-2" />
        <div class="about-area pt-100 pb-75">
            <div class="container">
                <h4>NOTICE</h4>
                <p>
                    Dear User, each time you access this website, you assent and agree to be bound by the following conditions of use and any revisions thereof, 
                    without limitation or qualification. You are required to visit this page periodically to view the latest terms and conditions to which you are bound.
                </p>
                <h4 class="mb-3">LEGAL DISCLAIMEROTICE</h4>
                <h5>Use of Information and contents</h5>
                <p>
                    The information/content published on the URUDI website should be used for general information purposes only and 
                    should not be used as a basis for making business or commercial decisions. You are advised to exercise due caution and/or seek independent 
                    advice before entering into any business or commercial decisions, based on the information herein. The information/content may amend or withdraw without notice.
                </p>
                <h5>No representation or warranty</h5>
                <p>
                    There is no guarantee or warranty that the site is free from any virus or other malicious, destructive or corrupting code, program or macro. 
                    No information sent to any client through this website or available on this website shall constitute any representation or warranty by 
                    URUDI regarding the credit-worthiness, financial performance or prospects, solvency, or viability of the company. This website may contain advice, 
                    opinions and statements of various professionals, experts, analysts, etc. that URUDI does not represent/endorse the accuracy or reliability thereof. 
                    Reliance thereon shall be at the risk of the user of this website. All information in this website is being provided on condition and understanding that 
                    the same is not being interpreted or relied on as legal, accounting, tax, financial, investment or other professional advice. URUDI may at any time 
                    (without being obliged to do so) update, edit, alter and/or remove any information in whole or in part and shall not be responsible for all or any 
                    actions that may subsequently result in any loss, damage and/or liability. Nothing contained herein is to be construed as a recommendation to use any 
                    product or process and URUDI makes no representation or warranty, express or implied that the use thereof will not infringe any patent, or otherwise.
                </p>
                <h5>Exclusion of Liability</h5>
                <p>
                    URUDI, its directors and employees shall accept no liability for any costs, loss or damage arising directly or indirectly (including special, incidental or 
                    consequential, punitive, or exemplary) from your use of this website or any linked site or inability to use by any party, howsoever arising, and including 
                    any loss, damage or expense arising from, but not limited to, any defect, error, omission, interruption, imperfection, fault, mistake or inaccuracy with 
                    this website, its contents or associated services, or due to any unavailability of the website or any part thereof or any contents or associated services.
                </p>
                <h5>Privacy and ownership of information</h5>
                <p>
                    Unless otherwise expressly set forth herein, any information, communication or material you transmit to this website by electronic mail or otherwise, 
                    including data, questions, comments, or suggestions will be treated as non-confidential and non-proprietary and will become the property of URUDI. 
                    Such information, communication or material may be used for any purpose, including but not limited to, reproduction, solicitations, disclosure, transmission, 
                    publication, broadcast, and posting. Furthermore, URUDI shall be free to use any ideas and concepts, contained in any communication you send to this website for 
                    any purpose whatsoever.
                </p>
                <h5>Intellectual Property Rights</h5>
                <p>
                    URUDI retains all rights (including copyrights, trademarks, patents as well as any other intellectual property right) in relation to all information 
                    provided on or via this website (including all texts, graphics and logos). All trademarks, services marks, trade names, logos, images and icons are 
                    proprietary to URUDI and nothing contained on the website should be construed as granting, by implication, estoppels, or otherwise, any license or right to 
                    use any trademark displayed on this website without the written permission of URUDI. Your use of the trademarks displayed on this website, or any other content 
                    on this website, except as provided herein, is strictly prohibited. Any unauthorized use of the intellectual property right may violate copyright laws, 
                    trademark laws and other regulations and statutes.
                </p>
                <h5>Links</h5>
                <p>
                    This website may contain links to other websites of subsidiary companies or affiliate companies of URUDI. This website may also contain links to external websites, 
                    having further linked websites, controlled or offered by third parties, in order to help you find relevant websites, services and products which may be of 
                    interest to you, quickly and easily. The contents displayed or the quality of services and products offered on such linked websites are not endorsed, verified 
                    or monitored by URUDI. URUDI is also not responsible for the owners or operators of such external websites or for any products or services they supply or for the 
                    contents of their websites and do not give or enter into any conditions, warranties, express or implied or other terms or representations in relation to any 
                    of these or accept any liability in relation to any of these and will not be responsible or liable in any way for the content of the linked website.      
                </p> 
            </div>
        </div>
        <Footer class="margin-zero" />
    </div>
</template>

<script>
// import Topbar from '../Layouts/Topbar'
import Navbar from '../Layouts/Navbar'
import PageBanner from '../Common/PageBanner'
import Footer from '../Layouts/Footer'

export default {
    name: 'AboutPageOne',
    components: {
        // Topbar,
        Navbar,
        PageBanner,
        Footer,
    }
}
</script>