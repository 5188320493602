<template>
    <footer class="footer-area with-black-background pt-100" style="z-index: 0;"> 
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-3 col-md-6">
                    <div class="single-footer-widget" data-aos="fade-up" data-aos-delay="50" data-aos-duration="500" data-aos-once="true">
                        <div class="widget-logo">
                            <router-link to="/">
                                <img src="../../assets/images/urudi-logo-colour-transparent.png" alt="image"  height="100" width="260">
                            </router-link>
                        </div>
                        <p>....</p>

                        <ul class="widget-social">
                            <li>
                                <a href="https://www.facebook.com/" target="_blank">
                                    <i class="ri-facebook-fill"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://twitter.com/?lang=en" target="_blank">
                                    <i class="ri-twitter-fill"></i>
                                </a>
                            </li>

                            <li>
                                <a href="https://www.youtube.com/" target="_blank">
                                    <i class="ri-youtube-fill"></i>
                                </a>
                            </li>
                            
                            <li>
                                <a href="https://www.instagram.com/" target="_blank">
                                    <i class="ri-instagram-line"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="single-footer-widget ps-5" data-aos="fade-up" data-aos-delay="60" data-aos-duration="600" data-aos-once="true">
                        <h3>Quick Links</h3>

                        <ul class="quick-links">
                            <li><router-link to="/about-us">About Us</router-link></li>
                            <li><router-link to="">Products</router-link></li>
                            <li><router-link to="">services</router-link></li>
                            <li><router-link to="">News and Articles</router-link></li>
                            <li><router-link to="">Apply for Loan</router-link></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="single-footer-widget ps-5" data-aos="fade-up" data-aos-delay="70" data-aos-duration="700" data-aos-once="true">
                        <h3>Pages</h3>

                        <ul class="quick-links">
                            <li><router-link to="/terms-and-conditions">Terms and Conditions</router-link></li>
                            <li><router-link to="/disclaimer-policy">Disclaimer Policy</router-link></li>
                            <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
                            <li><router-link to="/refund-policy">Refund Policy</router-link></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="single-footer-widget ps-5" data-aos="fade-up" data-aos-delay="70" data-aos-duration="700" data-aos-once="true">
                        <h3>Contact Us</h3>
                        <div class="custom-color mt-2 d-flex align-items-start">
                            <i class="ri-map-pin-line mt-1"></i> <span class="mx-1">No.3875, Abishek Complex,<br> 1st Floor, Maruthi Nagar,<br> Trichy-621216.</span>
                        </div>
                        <div class="custom-color mt-2 d-flex align-items-center">
                            <i class="ri-phone-line"></i> <span class="mx-1">+918838971512</span>
                        </div>
                        <!-- <div class="custom-color mt-2 d-flex align-items-center">
                            <i class="ri-phone-line"></i> <span class="mx-1">+91-4222254354</span>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>

        <div class="copyright-area">
            <div class="container">
                <div class="copyright-area-content">
                    <p>
                        &copy; {{currentYear}} URUDI Finance. All Rights Reserved. Powered by
                        <a href="https://www.roftr.com/" target="_blank"> Roftr</a>
                    </p>
                </div>
            </div>
        </div>
        <!-- <div class="footer-shape-1">
            <img src="../../assets/images/footer/footer-shape-1.png" alt="image">
        </div>
        <div class="footer-shape-2">
            <img src="../../assets/images/footer/footer-shape-2.png" alt="image">
        </div>
        <div class="footer-shape-3">
            <img src="../../assets/images/footer/footer-shape-3.png" alt="image">
        </div> -->
    </footer>
</template>
<style>
.custom-color{
    color:#CDC4D9 !important;
}
</style>
<script>
export default {
    name: 'Footer',
    data() {
        return {
            currentYear: new Date().getFullYear(),
        };
    }
}
</script>