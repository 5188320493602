<template>
    <div>
        <!-- <Topbar /> -->
        <Navbar />
        <PageBanner pageTitle="Products" className="page-banner-area bg-4" />
        <div class="projects-area pt-100 pb-75">
            <div class="container">
                <div class="section-title">
                    <span>Products</span>
                    <!-- <h2>Our Latest <b>Incredible</b> Client's Projects</h2> -->
                </div>

                <ul class="projects-filter-menu"> 
                    <li 
                        class="filter" 
                        :class="{active:itemFilterKey == 'personalLoan'}" 
                        v-on:click="itemFilterKey = 'personalLoan'" 
                    >
                        Personal Loan
                    </li>
                    <li 
                        class="filter" 
                        :class="{active:itemFilterKey == 'homeLoan'}"
                        v-on:click="itemFilterKey = 'homeLoan'"
                    >
                        Home Loan
                    </li>
                    <li 
                        class="filter" 
                        :class="{active:itemFilterKey == 'groupLoan'}"
                        v-on:click="itemFilterKey = 'groupLoan'"
                    >
                        Group Loan
                    </li>
                    <li 
                        class="filter" 
                        :class="{active:itemFilterKey == 'vehicleLoan'}"
                        v-on:click="itemFilterKey = 'vehicleLoan'"
                    >
                        Vehicle Loan
                    </li>
                </ul>  
                <div class="row justify-content-center">
                    <div class="col-lg-12 col-md-12" :class="itemFilterKey == 'personalLoan' ? '':'d-none'">
                        <div class="projects-details-area">
                            <div class="container">
                                <div class="projects-details-desc">
                                    <div class="article-projects-content">
                                        <div class="row align-items-center">
                                            <div class="col-lg-6 col-md-6">
                                                <div class="content">
                                                    <h3>Title 1</h3>
                                                    <p>Lorem ipsum dolor sit amet consetetur sadip scing elitr sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyamei erat sed diam voluptua at vero eos et accusam et justo duo.</p>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6">
                                                <div class="image">
                                                    <img src="../../assets/images/projects-details/projects-details-2.jpg" alt="image">
                                                </div>
                                            </div>
                                        </div>
                                        <ul class="projects-list">
                                            <li>
                                                <h4>Title 2</h4>
                                            </li>
                                            <li><i class="ri-checkbox-circle-line"></i> Lorem ipsum dolor sit amet consetetur sadipscing elitr sed diam nonumy eirmod tempor invidunt ut</li>
                                            <li><i class="ri-checkbox-circle-line"></i> Dolor sit amet consetetur sadipscing elitr sed diam nonumy eirmod tempor invidunt.</li>
                                            <li><i class="ri-checkbox-circle-line"></i> Consetetur sadipscing elitr sed diam nonumy eirmod tempor invidunt.</li>
                                            <li><i class="ri-checkbox-circle-line"></i> Sadipscing elitr sed diam nonumy eirmod tempor invidunt ut labore dolore magna aliquyam erat.</li>
                                            <li><i class="ri-checkbox-circle-line"></i> Sed diam nonumy eirmod tempor invidunt ut labore dolore magna aliquyam erat.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12" :class="itemFilterKey == 'homeLoan' ? '':'d-none'">
                        <div class="projects-details-area">
                            <div class="container">
                                <div class="projects-details-desc">
                                    <div class="article-projects-content">
                                        <div class="row align-items-center">
                                            <div class="col-lg-6 col-md-6">
                                                <div class="content">
                                                    <h3>Title 1</h3>
                                                    <p>Lorem ipsum dolor sit amet consetetur sadip scing elitr sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyamei erat sed diam voluptua at vero eos et accusam et justo duo.</p>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6">
                                                <div class="image">
                                                    <img src="../../assets/images/projects-details/projects-details-2.jpg" alt="image">
                                                </div>
                                            </div>
                                        </div>
                                        <ul class="projects-list">
                                            <li>
                                                <h4>Title 2</h4>
                                            </li>
                                            <li><i class="ri-checkbox-circle-line"></i> Lorem ipsum dolor sit amet consetetur sadipscing elitr sed diam nonumy eirmod tempor invidunt ut</li>
                                            <li><i class="ri-checkbox-circle-line"></i> Dolor sit amet consetetur sadipscing elitr sed diam nonumy eirmod tempor invidunt.</li>
                                            <li><i class="ri-checkbox-circle-line"></i> Consetetur sadipscing elitr sed diam nonumy eirmod tempor invidunt.</li>
                                            <li><i class="ri-checkbox-circle-line"></i> Sadipscing elitr sed diam nonumy eirmod tempor invidunt ut labore dolore magna aliquyam erat.</li>
                                            <li><i class="ri-checkbox-circle-line"></i> Sed diam nonumy eirmod tempor invidunt ut labore dolore magna aliquyam erat.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12" :class="itemFilterKey == 'groupLoan' ? '':'d-none'">
                        <div class="projects-details-area">
                            <div class="container">
                                <div class="projects-details-desc">
                                    <div class="article-projects-content">
                                        <div class="row align-items-center">
                                            <div class="col-lg-6 col-md-6">
                                                <div class="content">
                                                    <h3>Title 1</h3>
                                                    <p>Lorem ipsum dolor sit amet consetetur sadip scing elitr sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyamei erat sed diam voluptua at vero eos et accusam et justo duo.</p>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6">
                                                <div class="image">
                                                    <img src="../../assets/images/projects-details/projects-details-2.jpg" alt="image">
                                                </div>
                                            </div>
                                        </div>
                                        <ul class="projects-list">
                                            <li>
                                                <h4>Title 2</h4>
                                            </li>
                                            <li><i class="ri-checkbox-circle-line"></i> Lorem ipsum dolor sit amet consetetur sadipscing elitr sed diam nonumy eirmod tempor invidunt ut</li>
                                            <li><i class="ri-checkbox-circle-line"></i> Dolor sit amet consetetur sadipscing elitr sed diam nonumy eirmod tempor invidunt.</li>
                                            <li><i class="ri-checkbox-circle-line"></i> Consetetur sadipscing elitr sed diam nonumy eirmod tempor invidunt.</li>
                                            <li><i class="ri-checkbox-circle-line"></i> Sadipscing elitr sed diam nonumy eirmod tempor invidunt ut labore dolore magna aliquyam erat.</li>
                                            <li><i class="ri-checkbox-circle-line"></i> Sed diam nonumy eirmod tempor invidunt ut labore dolore magna aliquyam erat.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12" :class="itemFilterKey == 'vehicleLoan' ? '':'d-none'">
                        <div class="projects-details-area">
                            <div class="container">
                                <div class="projects-details-desc">
                                    <div class="article-projects-content">
                                        <div class="row align-items-center">
                                            <div class="col-lg-6 col-md-6">
                                                <div class="content">
                                                    <h3>Title 1</h3>
                                                    <p>Lorem ipsum dolor sit amet consetetur sadip scing elitr sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyamei erat sed diam voluptua at vero eos et accusam et justo duo.</p>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6">
                                                <div class="image">
                                                    <img src="../../assets/images/projects-details/projects-details-2.jpg" alt="image">
                                                </div>
                                            </div>
                                        </div>
                                        <ul class="projects-list">
                                            <li>
                                                <h4>Title 2</h4>
                                            </li>
                                            <li><i class="ri-checkbox-circle-line"></i> Lorem ipsum dolor sit amet consetetur sadipscing elitr sed diam nonumy eirmod tempor invidunt ut</li>
                                            <li><i class="ri-checkbox-circle-line"></i> Dolor sit amet consetetur sadipscing elitr sed diam nonumy eirmod tempor invidunt.</li>
                                            <li><i class="ri-checkbox-circle-line"></i> Consetetur sadipscing elitr sed diam nonumy eirmod tempor invidunt.</li>
                                            <li><i class="ri-checkbox-circle-line"></i> Sadipscing elitr sed diam nonumy eirmod tempor invidunt ut labore dolore magna aliquyam erat.</li>
                                            <li><i class="ri-checkbox-circle-line"></i> Sed diam nonumy eirmod tempor invidunt ut labore dolore magna aliquyam erat.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="projects-bg-shape-1">
                <img src="../../assets/images/projects/projects-bg-shape.png" alt="image">
            </div>
        </div>
        <Footer class="margin-zero" />
    </div>
</template>

<script>
// import Topbar from '../Layouts/Topbar'
import Navbar from '../Layouts/Navbar'
import PageBanner from '../Common/PageBanner'
import Footer from '../Layouts/Footer'

export default {
    name: 'ShopPage',
    components: {
        // Topbar,
        Navbar,
        PageBanner,
        Footer,
    },
    data: function (){
        return {
            itemFilterKey: 'personalLoan',
        }
    },
    computed: {
        itemFilter(){
            return this[this.itemFilterKey]
        },
    }
}
</script>