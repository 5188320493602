<template>
    <div>
        <!-- <Topbar /> -->
        <Navbar />
        <PageBanner pageTitle="Payments" className="page-banner-area bg-2" />
        <div class="about-area">
            <div class="container">
                <div class="rounded">
                    <div class="courses-details-desc">
                        <div class="courses instructor mb-2">
                            <h3>Profile</h3>
                            <div class="row align-items-center">
                                <div class="col-lg-2 col-md-2">
                                    <div class="instructor-image d-flex justify-content-center" data-tilt="">
                                        <img src="../../assets/images/no-image.png" alt="image">
                                        <!-- <img :src="adminUrl+customer_info.applicant_photo" alt="image">  -->
                                    </div>
                                </div>
                                <div class="col-lg-10 col-md-10">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="instructor-content">
                                                <h5 class="custom-font-1 mt-2 mb-0 text-center">Harikrishnan R<span
                                                        class="text-danger fw-bolder fs-5">(C005)</span></h5>
                                                <div class="row my-3 gap-2">
                                                    <div class="col-md-12">
                                                        <h5 class="mb-2 custom-title"><b>Customer Profile</b></h5>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="row">
                                                            <div class="col-md-8">
                                                                <div class="row gap-2">
                                                                    <div class="col-md-12">
                                                                        <div class="d-flex align-items-center">
                                                                            <div
                                                                                class="mr-2 text-green fw-bold custom-font-2">
                                                                                Mobile No:</div>
                                                                            <div class="mx-1 custom-font-2">9876541230
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-12">
                                                                        <div class="d-flex align-items-start">
                                                                            <div
                                                                                class="mr-2 text-green fw-bold custom-font-2">
                                                                                Address:</div>
                                                                            <div class="mx-1">
                                                                                <div class="custom-font-2">11B, Hudcco
                                                                                    Colony</div>
                                                                                <!-- <div>{{ customer_info.address_line2 }}</div> -->
                                                                                <div class="custom-font-2">Peelamedu,
                                                                                    Coimbatore</div>
                                                                                <!-- <div>{{ customer_info.village }}, {{ customer_info.city }}</div> -->
                                                                                <div class="custom-font-2">Tamilnadu
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4">
                                                                <div class="d-flex align-items-center">
                                                                    <div class="mr-2 text-green fw-bold custom-font-2">
                                                                        Application No:</div>
                                                                    <div class="fw-bold mx-1 custom-font-2">1000124
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="separator"></div>
                                    <div class="instructor-content">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <h5 class="mb-3 custom-title"><b>Loan Profile</b></h5>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <span class="fw-bold text-green custom-font-2">Loan Type
                                                            :</span> <span class="custom-font-2 text-dark">PERSONAL LOAN
                                                            1 Yr</span>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <span class="fw-bold text-green custom-font-2">Loan Amount
                                                            :</span> <span
                                                            class="custom-font-2 text-dark">&#8377;50000</span>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <span class="fw-bold text-green custom-font-2">Interest :</span>
                                                        <span class="custom-font-2 text-dark">&#8377;32773 </span>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <span class="fw-bold text-green custom-font-2">Loan Duration
                                                            :</span> <span class="custom-font-2 text-dark">24 months
                                                        </span>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <span class="fw-bold text-green custom-font-2">Loan Disbursement
                                                            Date :</span> <span
                                                            class="custom-font-2 text-dark">10-Apr-2024 </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="courses-details-desc mt-0">
                        <div class="courses instructor">
                            <h3>EMI Payments</h3>
                            <div class="table-responsive">
                                <table class="table table-bordered emi-table">
                                    <thead>
                                        <tr class="bg-secondary text-white">
                                            <th class="text-center">Ins.no</th>
                                            <th class="text-center w-150px">Due Date</th>
                                            <th class="text-center">Instalment</th>
                                            <th class="text-center">Pay</th>
                                            <th class="text-center">Paid Amount</th>
                                            <th class="text-center">Balance</th>
                                        </tr>
                                    </thead>
                                    <tbody id="payment-body" v-on:click="verifyPayment">
                                        <tr>
                                            <td class="border text-center">1</td>
                                            <td class="border text-center w-150px">17-Jun-2024</td>
                                            <td class="border text-center">₹5</td>
                                            <td class="border text-center"><button type='button' class='btn btn-warning px-3 text-white' name='get_payment_info' data-pnum='1' data-accno='1000124'>Pay</button></td>
                                            <td class="border text-center">₹3445</td>
                                            <td class="border text-center">₹82773</td>
                                        </tr>
                                        <tr>
                                            <td class="border text-center">2</td>
                                            <td class="border text-center w-150px">10-Jul-2024</td>
                                            <td class="border text-center">₹3450</td>
                                            <td class="border text-center"></td>
                                            <td class="border text-center">₹0</td>
                                            <td class="border text-center">₹82773</td>
                                        </tr>
                                        <tr>
                                            <td class="border text-center">3</td>
                                            <td class="border text-center w-150px">10-Aug-2024</td>
                                            <td class="border text-center">₹3450</td>
                                            <td class="border text-center"></td>
                                            <td class="border text-center">₹0</td>
                                            <td class="border text-center">₹82773</td>
                                        </tr>
                                        <tr>
                                            <td class="border text-center">4</td>
                                            <td class="border text-center w-150px">10-Sep-2024</td>
                                            <td class="border text-center">₹3450</td>
                                            <td class="border text-center"></td>
                                            <td class="border text-center">₹0</td>
                                            <td class="border text-center">₹82773</td>
                                        </tr>
                                        <tr>
                                            <td class="border text-center">5</td>
                                            <td class="border text-center w-150px">10-Oct-2024</td>
                                            <td class="border text-center">₹3450</td>
                                            <td class="border text-center"></td>
                                            <td class="border text-center">₹0</td>
                                            <td class="border text-center">₹82773</td>
                                        </tr>
                                        <tr>
                                            <td class="border text-center">6</td>
                                            <td class="border text-center w-150px">10-Nov-2024</td>
                                            <td class="border text-center">₹3450</td>
                                            <td class="border text-center"></td>
                                            <td class="border text-center">₹0</td>
                                            <td class="border text-center">₹82773</td>
                                        </tr>
                                        <tr>
                                            <td class="border text-center">7</td>
                                            <td class="border text-center w-150px">10-Dec-2024</td>
                                            <td class="border text-center">₹3450</td>
                                            <td class="border text-center"></td>
                                            <td class="border text-center">₹0</td>
                                            <td class="border text-center">₹82773</td>
                                        </tr>
                                        <tr>
                                            <td class="border text-center">8</td>
                                            <td class="border text-center w-150px">10-Jan-2025</td>
                                            <td class="border text-center">₹3450</td>
                                            <td class="border text-center"></td>
                                            <td class="border text-center">₹0</td>
                                            <td class="border text-center">₹82773</td>
                                        </tr>
                                        <tr>
                                            <td class="border text-center">9</td>
                                            <td class="border text-center w-150px">10-Feb-2025</td>
                                            <td class="border text-center">₹3450</td>
                                            <td class="border text-center"></td>
                                            <td class="border text-center">₹0</td>
                                            <td class="border text-center">₹82773</td>
                                        </tr>
                                        <tr>
                                            <td class="border text-center">10</td>
                                            <td class="border text-center w-150px">10-Mar-2025</td>
                                            <td class="border text-center">₹3450</td>
                                            <td class="border text-center"></td>
                                            <td class="border text-center">₹0</td>
                                            <td class="border text-center">₹82773</td>
                                        </tr>
                                        <tr>
                                            <td class="border text-center">11</td>
                                            <td class="border text-center w-150px">10-Apr-2025</td>
                                            <td class="border text-center">₹3450</td>
                                            <td class="border text-center"></td>
                                            <td class="border text-center">₹0</td>
                                            <td class="border text-center">₹82773</td>
                                        </tr>
                                        <tr>
                                            <td class="border text-center">12</td>
                                            <td class="border text-center w-150px">10-May-2025</td>
                                            <td class="border text-center">₹3450</td>
                                            <td class="border text-center"></td>
                                            <td class="border text-center">₹0</td>
                                            <td class="border text-center">₹82773</td>
                                        </tr>
                                        <tr>
                                            <td class="border text-center">13</td>
                                            <td class="border text-center w-150px">10-Jun-2025</td>
                                            <td class="border text-center">₹3450</td>
                                            <td class="border text-center"></td>
                                            <td class="border text-center">₹0</td>
                                            <td class="border text-center">₹82773</td>
                                        </tr>
                                        <tr>
                                            <td class="border text-center">14</td>
                                            <td class="border text-center w-150px">10-Jul-2025</td>
                                            <td class="border text-center">₹3450</td>
                                            <td class="border text-center"></td>
                                            <td class="border text-center">₹0</td>
                                            <td class="border text-center">₹82773</td>
                                        </tr>
                                        <tr>
                                            <td class="border text-center">15</td>
                                            <td class="border text-center w-150px">10-Aug-2025</td>
                                            <td class="border text-center">₹3450</td>
                                            <td class="border text-center"></td>
                                            <td class="border text-center">₹0</td>
                                            <td class="border text-center">₹82773</td>
                                        </tr>
                                        <tr>
                                            <td class="border text-center">16</td>
                                            <td class="border text-center w-150px">10-Sep-2025</td>
                                            <td class="border text-center">₹3450</td>
                                            <td class="border text-center"></td>
                                            <td class="border text-center">₹0</td>
                                            <td class="border text-center">₹82773</td>
                                        </tr>
                                        <tr>
                                            <td class="border text-center">17</td>
                                            <td class="border text-center w-150px">10-Oct-2025</td>
                                            <td class="border text-center">₹3450</td>
                                            <td class="border text-center"></td>
                                            <td class="border text-center">₹0</td>
                                            <td class="border text-center">₹82773</td>
                                        </tr>
                                        <tr>
                                            <td class="border text-center">18</td>
                                            <td class="border text-center w-150px">10-Nov-2025</td>
                                            <td class="border text-center">₹3450</td>
                                            <td class="border text-center"></td>
                                            <td class="border text-center">₹0</td>
                                            <td class="border text-center">₹82773</td>
                                        </tr>
                                        <tr>
                                            <td class="border text-center">19</td>
                                            <td class="border text-center w-150px">10-Dec-2025</td>
                                            <td class="border text-center">₹3450</td>
                                            <td class="border text-center"></td>
                                            <td class="border text-center">₹0</td>
                                            <td class="border text-center">₹82773</td>
                                        </tr>
                                        <tr>
                                            <td class="border text-center">20</td>
                                            <td class="border text-center w-150px">10-Jan-2026</td>
                                            <td class="border text-center">₹3450</td>
                                            <td class="border text-center"></td>
                                            <td class="border text-center">₹0</td>
                                            <td class="border text-center">₹82773</td>
                                        </tr>
                                        <tr>
                                            <td class="border text-center">21</td>
                                            <td class="border text-center w-150px">10-Feb-2026</td>
                                            <td class="border text-center">₹3450</td>
                                            <td class="border text-center"></td>
                                            <td class="border text-center">₹0</td>
                                            <td class="border text-center">₹82773</td>
                                        </tr>
                                        <tr>
                                            <td class="border text-center">22</td>
                                            <td class="border text-center w-150px">10-Mar-2026</td>
                                            <td class="border text-center">₹3450</td>
                                            <td class="border text-center"></td>
                                            <td class="border text-center">₹0</td>
                                            <td class="border text-center">₹82773</td>
                                        </tr>
                                        <tr>
                                            <td class="border text-center">23</td>
                                            <td class="border text-center w-150px">10-Apr-2026</td>
                                            <td class="border text-center">₹3450</td>
                                            <td class="border text-center"></td>
                                            <td class="border text-center">₹0</td>
                                            <td class="border text-center">₹82773</td>
                                        </tr>
                                        <tr>
                                            <td class="border text-center">24</td>
                                            <td class="border text-center w-150px">10-May-2026</td>
                                            <td class="border text-center">₹3423</td>
                                            <td class="border text-center"></td>
                                            <td class="border text-center">₹0</td>
                                            <td class="border text-center">₹82773</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer class="margin-zero" />
    </div>
</template>
<style>
.w-250px {
    width: 250px !important;
}

.w-150px {
    width: 150px !important;
}

.courses-details-desc .courses h3 {
    padding-bottom: 10px;
    border-bottom: 4px solid #64b346 !important;
    margin-bottom: 25px;
}

.separator {
    padding-bottom: 10px;
    border-bottom: 4px solid #64b346 !important;
    margin-bottom: 25px;
}

.text-green {
    color: #64b346 !important;
}

.custom-title b {
    background: radial-gradient(circle, #a66bff, #c666ef, #dd62df, #ee61cf, #fb64bf);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.emi-table td {
    vertical-align: middle;
}

.emi-table th:nth-child(2),
.emi-table td:nth-child(2) {
    width: 150px !important;
    min-width: 120px !important;
}

.emi-table {
    font-size: 15px !important;
}
</style>
<script>
import { AdminPath, BasePath, instance } from '../../api';
// import Topbar from '../Layouts/Topbar'
import Navbar from '../Layouts/Navbar'
import PageBanner from '../Common/PageBanner'
import Footer from '../Layouts/Footer'
export default {
    name: 'ShopPage',
    components: {
        //Topbar,
        Navbar,
        PageBanner,
        Footer,
    },
    data() {
        return {
            customer_info: [],
            adminUrl: AdminPath,
        }
    },
    async mounted() {
        this.clearPaymentResponse();
        await this.getCustomerDetails();
        const script1 = document.createElement("script");
        script1.src = "https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js";
        script1.addEventListener("load", this.setLoaded);
        document.body.appendChild(script1);

        const script2 = document.createElement("script");
        script2.src = "https://www.paynimo.com/paynimocheckout/server/lib/checkout.js";
        script2.addEventListener("load", this.setLoaded);
        document.body.appendChild(script2);
    },
    methods: {
        async getCustomerDetails() {
            return 1;
            var token = "";
            let formData = new FormData();
            formData.append('token', token);
            await instance.post(BasePath + 'get-customer-info', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
                .then(response => {
                    var res = response.data.response_data;
                    if (res) {
                        this.customer_info = res;
                        document.getElementById("payment-body").innerHTML = "";
                        document.getElementById("payment-body").innerHTML = response.data.emi_info;
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        clearPaymentResponse: function () {
            // localStorage.removeItem("payment_response");
            // var token = localStorage.getItem('auth_token');
            // if (!token) {
            //     this.$router.push({ path: ("/customer-login") });
            // }
        },
        verifyPayment(e) {
            if (e.target.name == "get_payment_info") {
                var account_no = e.target.getAttribute("data-accno");
                var payment_number = e.target.getAttribute("data-pnum");
                var token = "1";
                if (!account_no || !payment_number || !token) {
                    this.$toast.error('Invalid Access!', {
                        icons: 'shopping_cart',
                        position: "bottom-right",
                        duration: 3000
                    });
                    return false;
                }
                if (!confirm("Are you sure you want to Pay this Emi?")) {
                    return false;
                }
                let formData = new FormData();
                formData.append('token', token);
                formData.append('acc_no', account_no);
                formData.append('pay_no', payment_number);
                instance.post(BasePath + "connect-test-payment", formData, { headers: { 'Content-Type': 'multipart/form-data' } })
                    .then(response => {
                        if (response.data.status == "success") {
                            var result = response.data.response_data;
                            // console.log(response.data.response_data);
                            var configJson =
                            {
                                'tarCall': false,
                                'features': {
                                    'showPGResponseMsg': true,
                                    'enableNewWindowFlow': true,   //for hybrid applications please disable this by passing false
                                    'enableAbortResponse': true,
                                    'enableExpressPay': true,  //if unique customer identifier is passed then save card functionality for end  end customer
                                    'enableInstrumentDeRegistration': true,  //if unique customer identifier is passed then option to delete saved card by end customer
                                    'enableMerTxnDetails': true,
                                    'siDetailsAtMerchantEnd': false,
                                    'enableSI': false,
                                    'hideSIDetails': false,
                                    'enableDebitDay': false,
                                    'expandSIDetails': false,
                                    'enableTxnForNonSICards': false,
                                    'showSIConfirmation': false,
                                    'showSIResponseMsg': false,
                                },
                                'consumerData': {
                                    'deviceId': 'WEBSH2',
                                    //possible values 'WEBSH1', 'WEBSH2' and 'WEBMD5'
                                    //'debitDay':'10',
                                    'token': result.hash,
                                    'returnUrl': result.data[12],
                                    /*'redirectOnClose': 'https://www.tekprocess.co.in/MerchantIntegrationClient/MerchantResponsePage.jsp',*/
                                    'responseHandler': this.handleResponse,
                                    'paymentMode': 'all',
                                    //"paymentModeOrder": ["wallets","cards","netBanking","imps","cashCards","UPI","MVISA","debitPin","NEFTRTGS","emiBanks"],  
                                    'checkoutElement': '',
                                    'merchantLogoUrl': 'https://www.urudi.org/logo.png',  //provided merchant logo will be displayed
                                    'merchantId': result.data[0],
                                    'currency': result.data[15],
                                    'consumerId': result.data[8],  //Your unique consumer identifier to register a eMandate/eNACH
                                    'consumerMobileNo': result.data[9],
                                    'consumerEmailId': result.data[10],
                                    'txnId': result.data[1],   //Unique merchant transaction ID
                                    'items': [{
                                        'itemId': result.data[14],
                                        'amount': result.data[2],
                                        'comAmt': '0'
                                    }],
                                    'cartDescription': '}{custname:' + result.data[13],
                                    'merRefDetails': [
                                        { "name": "Txn. Ref. ID", "value": result.data[1] }
                                    ],
                                    'customStyle': {
                                        'PRIMARY_COLOR_CODE': '#3977b7',   //merchant primary color code
                                        'SECONDARY_COLOR_CODE': '#FFFFFF',   //provide merchant's suitable color code
                                        'BUTTON_COLOR_CODE_1': '#1969bb',   //merchant's button background color code
                                        'BUTTON_COLOR_CODE_2': '#FFFFFF'   //provide merchant's suitable color code for button text
                                    },
                                    'accountNo': result.data[11],    //Pass this if accountNo is captured at merchant side for eMandate/eNACH
                                    'accountHolderName': result.data[16],  //Pass this if accountHolderName is captured at merchant side for ICICI eMandate & eNACH registration this is mandatory field, if not passed from merchant Customer need to enter in Checkout UI.
                                    'ifscCode': result.data[17],        //Pass this if ifscCode is captured at merchant side.
                                    'accountType': result.data[18],  //Required for eNACH registration this is mandatory field
                                    'debitStartDate': result.data[3],
                                    'debitEndDate': result.data[4],
                                    'maxAmount': result.data[5],
                                    'amountType': result.data[6],
                                    'frequency': result.data[7]  //  Available options DAIL, WEEK, MNTH, QURT, MIAN, YEAR, BIMN and ADHO
                                }
                            };
                            // console.log(configJson); 
                            $.pnCheckout(configJson);
                            if (configJson.features.enableNewWindowFlow) {
                                pnCheckoutShared.openNewWindow();
                            }
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        },
        handleResponse(res) {
            if (typeof res != 'undefined' && typeof res.paymentMethod != 'undefined' && typeof res.paymentMethod.paymentTransaction != 'undefined' && typeof res.paymentMethod.paymentTransaction.statusCode != 'undefined' && res.paymentMethod.paymentTransaction.statusCode == '0300') {
                // success block
            } else if (typeof res != 'undefined' && typeof res.paymentMethod != 'undefined' && typeof res.paymentMethod.paymentTransaction != 'undefined' && typeof res.paymentMethod.paymentTransaction.statusCode != 'undefined' && res.paymentMethod.paymentTransaction.statusCode == '0398') {
                // initiated block
            } else {
                // error block
            }
        }
    }
}
</script>