<template>
    <div>
        <!-- <Topbar /> -->
        <Navbar />
        <PageBanner pageTitle="EMI Calculator" className="page-banner-area bg-2" />
        <div class="about-area ptb-100">
            <div class="container">
                <div class="row">
                    <div class="col-md-4">
                        <form action="#" method="POST" autocomplete="off" class="border border-dark rounded">                            
                            <div class="p-4">
                                <b class="text-dark fs-4">Loan Calculator</b>
                                <div class="mt-2">
                                    <label for="amount" class="fw-bold text-dark">Amount</label>
                                    <input type="text" class="form-control border border-primary" name="amount" id="amount" placeholder="Enter the Amount">
                                </div>
                                <div class="mt-2">
                                    <label for="interest_rate" class="fw-bold text-dark">Interest Rate %</label>
                                    <input type="text" class="form-control border border-primary" name="interest_rate" id="interest_rate" placeholder="Interest Rate %">
                                </div>
                                <div class="mt-2 d-flex align-items-center justify-content-between flex-wrap gap-2">
                                    <div class="">
                                        <label for="interest_rate" class="fw-bold text-dark">Duration</label>
                                        <select class="form-select border border-primary w-250px" name="duration" id="duration">
                                            <option value="">Select Duration</option>
                                            <option value="6">6 Months</option>
                                            <option value="12">12 Months</option>
                                            <option value="18">18 Months</option>
                                            <option value="24">24 Months</option>
                                        </select>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-center flex-column gap-2">                                        
                                        <button class="btn btn-success px-3" type="button">Weeks</button>
                                        <button class="btn btn-primary" type="button">Months</button>
                                    </div>                                    
                                </div>
                                <div class="mt-2">
                                    <label for="amount" class="fw-bold text-dark">Interest Type</label>
                                    <div class="d-flex flex-wrap gap-3 mt-1">
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" name="interest_type" id="diminishing" checked>
                                            <label class="form-check-label" for="diminishing">
                                                Diminishing
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" name="interest_type" id="flat_interest">
                                            <label class="form-check-label" for="flat_interest">
                                                Flat Interest
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-5 text-center">
                                    <button class="btn btn-success">Calculate</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="col-md-8">
                        <div class="border border-dark rounded p-4">
                            <b class="text-dark fs-4">EMI Details</b>
                            <div>
                                <table class="table table-bordered">
                                    <thead>
                                        <tr class="bg-primary text-white">
                                            <th>Period</th>
                                            <th>Payment</th>
                                            <th>Interest</th>
                                            <th>Principal</th>
                                            <th>Balance</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>        
        </div>        
        <Footer class="margin-zero" />
    </div>
</template>
<style>
.w-250px{
    width:250px !important;
}
</style>
<script>
// import Topbar from '../Layouts/Topbar'
import Navbar from '../Layouts/Navbar'
import PageBanner from '../Common/PageBanner'
import Footer from '../Layouts/Footer'

export default {
    name: 'ShopPage',
    components: {
        // Topbar,
        Navbar,
        PageBanner,
        Footer,
    },
}
</script>