<template>
    <div>
        <!-- <Topbar /> -->
        <Navbar />
        <PageBanner pageTitle="Privacy Policy" className="page-banner-area bg-2" />
        <div class="about-area pt-100 pb-75">
            <div class="container">
                <p>
                    Universal Rural And Urban Development Initiatives is committed to protect the privacy of personal information shared by you either by visiting our website or by filling our application form or participating in our surveys. This Privacy Policy lays down the collection, usage, protection and sharing of your Personal Information or Sensitive Personal Data, in accordance with provisions of the Information Technology (Reasonable Security practices and procedures and sensitive personal data or information) Rules, 2011. This Privacy Policy shall be amended from time to time to comply with our business requirements and applicable laws. The amendments made shall be updated on this website. You may visit this website to view the changes in policy. By using this website or by providing your information to us, you consent that your personal information may be collected and used by us as per this Privacy Policy.
                </p>
                <h4>Personal Information</h4>
                <p>
                    Personal information refers to such information that can directly or indirectly identify a person like the full name or email address, etc.
                </p>
                <p>
                    The type of Personal Information collected from you may include your full name, address, telephone number and email address which shall be used by us for providing the services to you.
                </p>
                <h4>Sensitive Personal Data</h4>
                <p>
                    Sensitive Personal data refers to the information of a person that is more sensitive in nature which includes passwords, financial information namely bank account, biometric information, etc.
                </p>
                <h4>Collection of information</h4>
                <p>
                    The information pertaining to your identity and demographics shall be collected by us for enabling us to provide better services to you.
                </p>
                <h4>Usage of your information</h4>
                <p>
                    Any of the information we collect from you may be used in one of the following ways:
                    <br>
                    <ul>
                        <li>
                            To personalize your experience – Your information helps us to respond to your individual needs in a better way.
                        </li>
                        <li>
                            To verify your identity as per applicable laws.
                        </li>
                        <li>
                            To improve your experience with our website – We continually strive to improve our website offerings based on the information and feedback we receive from you
                        </li>
                        <li>
                            To improve customer service – Your information helps us to more effectively respond to your customer service requests and support needs
                        </li>
                        <li>
                            To process your transactions easily
                        </li>
                        <li>
                            To administer a contest, promotion, survey or other site feature – We shall reach out to you when we are conducting any contests or surveys. You may opt out if you do not wish to be included
                        </li>
                        <li>
                            To send periodic emails – The email address you provide may be used to send you information, respond to inquiries, and/or other requests or questions.
                        </li>
                        <li>
                            To keep you informed about our new products or services unless you unsubscribe to our emails
                        </li>
                        <li>
                            To carry out research and analysis to study customer use and experience on an anonymous or personalized basis
                        </li>
                        <li>
                            Other usages that you may consent to <br>
                        Your information, whether public or private, will not be sold, exchanged, transferred, or given to any other company for any reason whatsoever, without your consent, other than for the express purpose of delivering the purchased product or service requested. We may, however, share personal information to our group companies, for providing better services to you, if you expressly consent to do so.
                        </li>
                    </ul>
                </p>                
                <h4>Protection of information</h4>
                <p>
                    We implement a variety of security measures to protect your personal information from unauthorized access or disclosure. Further our employees who have limited access to your personal information are bound by Non-Disclosure and Confidentiality Policies.
                </p>
                <h4>Disclosure of information</h4>

                <p>
                    We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties comply with applicable laws and agree to keep this information confidential.
                </p>
                <p>
                    We may have to disclose your personal information or sensitive data with Government agencies or other law enforcement agencies, if we are called upon to do so, for the purpose of investigation or otherwise. Further, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.
                </p>          
            </div>
        </div>
        <Footer class="margin-zero" />
    </div>
</template>

<script>
// import Topbar from '../Layouts/Topbar'
import Navbar from '../Layouts/Navbar'
import PageBanner from '../Common/PageBanner'
import Footer from '../Layouts/Footer'

export default {
    name: 'AboutPageOne',
    components: {
        // Topbar,
        Navbar,
        PageBanner,
        Footer,
    }
}
</script>