<template>
    <div class="container ptb-100">
        <div class="map-location">
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15672.386531495775!2d78.7122081!3d10.8802545!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3baaf79bcd0b1aa5%3A0xe4537ca33994d4ed!2sUniversal%20Rural%20And%20Urban%20Development%20Initiatives!5e0!3m2!1sen!2sin!4v1704957005293!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Map'
}
</script>