<template>
    <div>
        <!-- <Topbar /> -->
        <Navbar />
        <PageBanner pageTitle="About Us" className="page-banner-area bg-2" />
        <AboutTwo />
        <!-- <Testimonials /> -->
        <Footer class="margin-zero" />
    </div>
</template>

<script>
// import Topbar from '../Layouts/Topbar'
import Navbar from '../Layouts/Navbar'
import PageBanner from '../Common/PageBanner'
import AboutTwo from '../AboutUs/About'
// import Testimonials from '../AboutUs/Testimonials'
import Footer from '../Layouts/Footer'

export default {
    name: 'AboutPageOne',
    components: {
        // Topbar,
        Navbar,
        PageBanner,
        // Testimonials,
        AboutTwo,
        Footer,
    }
}
</script>