<template>
    <div>
        <!-- <Topbar /> -->
        <Navbar />
        <!-- <PageBanner pageTitle="Login" className="page-banner-area bg-2" /> -->
        <div class="about-area">
            <div class="container">
                <div class="partner-area ptb-100 rounded px-1">
                    <!-- <div class="row"> -->
                        <div class="login-form p-4">
                            <div class="text-center">   
                                <div>
                                    <h5 class="text-success">Welcome To URUDI FINANCE Payment Portal</h5>
                                </div>                             
                                <h4 class="my-3">Login</h4>
                            </div>
                            <form method="post" autocomplete="off">                                
                                <!-- <div class="mb-5">
                                    <ul class="nav nav-tabs ">
                                        <li class="nav-item">
                                            <a class="nav-link active" href="#regMobile">Mobile No.</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" href="#regAccNumber">Link</a>
                                        </li>
                                    </ul>
                                </div> -->
                                <div class="">
                                    <div class="form-group">
                                        <label class="d-flex align-items-center fs-6"><i class="ri-smartphone-line"></i> Reg. Mobile Number</label>
                                        <input type="text" id="regMobile" class="form-control border border-dark" placeholder="Your Reg. Mobile Number" maxlength="10" inputmode="numeric" onkeyup="if (/\D/g.test(this.value)) this.value = this.value.replace(/\D/g,'')">
                                    </div>
                                </div>
                                <div v-if="otp_status">
                                    <div class="form-group">
                                        <label class="d-flex align-items-center fs-6"><i class="ri-hashtag"></i> OTP</label>
                                        <input type="text" id="otpCredential" class="form-control border border-dark" placeholder="Enter OTP" maxlength="6" inputmode="numeric" onkeyup="if (/\D/g.test(this.value)) this.value = this.value.replace(/\D/g,'')">
                                    </div>
                                </div>
                                <button type="button" v-if="!otp_success" class="default-btn" id="sendOTP" @click="sendOTP()">Submit</button>
                                <button type="button" v-if="otp_status" class="default-btn" id="verifyOTP" @click="verifyOTP()">Submit</button> 
                                <div id="error_codes" class="my-3 text-center">

                                </div>
                            </form>
                        </div>
                    <!-- </div> -->
                </div>
            </div>        
        </div>        
        <Footer class="margin-zero" />
    </div>
</template>
<style>
.w-250px{
    width:250px !important;
}
</style>
<script>
// import Topbar from '../Layouts/Topbar'
import Navbar from '../Layouts/Navbar'
// import PageBanner from '../Common/PageBanner'
import Footer from '../Layouts/Footer'
import { BasePath, instance } from '../../api'; 
export default {
    name: 'ShopPage',
    components: {
        // Topbar,
        Navbar,
        // PageBanner,
        Footer,
    },
    data() {
        return {
            'otp_status': false,
            'otp_success': false,
        }
    },
    mounted: function() { 
        this.clearPaymentResponse();	
    },
    methods: {
        sendOTP: function(){
            document.getElementById("error_codes").innerHTML= "";
            var mobile = document.getElementById("regMobile").value;
            if(!mobile){
                document.getElementById("error_codes").innerHTML = "<span class='text-danger fw-bold'>Please Enter The Valid Mobile Number!<span>";
                return false;
            }
            document.getElementById("sendOTP").style.cursor = "not-allowed";
            document.getElementById("sendOTP").disabled     = true;
            let formData = new FormData();
            formData.append('mobile', mobile);
            instance.post(BasePath+'send-otp',formData,{  headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {
                var res = response.data.status;
                if(res=="success"){ 
                    document.getElementById("error_codes").innerHTML    = "<span class='text-success fw-bold'>"+response.data.response_msg+"<span>";
                    document.getElementById("regMobile").style.cursor   = "not-allowed";
                    document.getElementById("regMobile").readOnly       = true;
                    this.otp_status     = true;
                    this.otp_success    = true;
                    setTimeout(function () {
                        document.getElementById("error_codes").innerHTML= "";
                    },2500);
                }else{
                    document.getElementById("error_codes").innerHTML    = "<span class='text-danger fw-bold'>"+response.data.response_msg+"<span>";
                    document.getElementById("sendOTP").style.cursor     = "default";
                    document.getElementById("sendOTP").disabled         = false;
                    setTimeout(function () {
                        document.getElementById("error_codes").innerHTML= "";
                    },2500);
                    this.otp_status = false;
                }
            }).catch(function (error) {
                console.log(error);
                document.getElementById("error_codes").innerHTML    = "<span class='text-danger fw-bold'>Please Enter Your Valid Mobile Number!<span>";
                document.getElementById("sendOTP").style.cursor     = "default";
                document.getElementById("sendOTP").disabled         = false;
                setTimeout(function () {
                    document.getElementById("error_codes").innerHTML= "";
                },2500);
                this.otp_status = false;
            });
        },
        verifyOTP: function(){      
            document.getElementById("error_codes").innerHTML = "";      
            var mobile  = document.getElementById("regMobile").value;
            var otp     = document.getElementById("otpCredential").value;
            if(!mobile){
                document.getElementById("error_codes").innerHTML = "<span class='text-danger fw-bold'>Please Enter The Valid Mobile Number!<span>";
                return false;
            }
            if(!otp){
                document.getElementById("error_codes").innerHTML = "<span class='text-danger fw-bold'>Please Enter The OTP!<span>";
                return false;
            }
            document.getElementById("verifyOTP").style.cursor = "not-allowed";
            document.getElementById("verifyOTP").disabled     = true;
            let formData = new FormData();
            formData.append('mobile', mobile);
            formData.append('otp', otp);
            instance.post(BasePath+'verify-otp',formData,{  headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {
                var res = response.data.status;
                if(res=="success"){
                    var token = response.data.token;
                    document.getElementById("error_codes").innerHTML    = "<span class='text-success fw-bold'>"+response.data.response_msg+"<span>";
                    var self = this;
                    setTimeout(function () {
                        localStorage.setItem('auth_token',token);
                        document.getElementById("error_codes").innerHTML= "";
                        self.$router.push({path: ("/payments")});  
                    },2000); 
                }else{
                    document.getElementById("error_codes").innerHTML    = "<span class='text-success fw-bold'>"+response.data.response_msg+"<span>";
                    document.getElementById("verifyOTP").style.cursor   = "default";
                    document.getElementById("verifyOTP").disabled       = false;
                    setTimeout(function () {
                        document.getElementById("error_codes").innerHTML= "";
                    },2000);
                }
            }).catch(function (error) {
                console.log(error);
                document.getElementById("error_codes").innerHTML    = "<span class='text-success fw-bold'>OTP Verification Failed!<span>";
                document.getElementById("verifyOTP").style.cursor   = "default";
                document.getElementById("verifyOTP").disabled       = false;
                setTimeout(function () {
                    document.getElementById("error_codes").innerHTML= "";
                },2000);
            });
        },
        clearPaymentResponse: function(){
            localStorage.removeItem("payment_response");
            var token = localStorage.getItem('auth_token');          
            if(token){                
				this.$router.push({path: ("/payments")});                 
            }
        }
    }
}
</script>