<template>
    <div>
        <!-- <Topbar /> -->
        <Navbar />
        <PageBanner pageTitle="Refund Policy" className="page-banner-area bg-2" />
        <div class="about-area pt-100 pb-75">
            <div class="container">
                <h4>Interest and Fees</h4>
                <p>- Clearly outline the interest rates and fees associated with the microfinance loans.</p>
                <p>- Specify whether any fees are refundable under certain circumstances.</p>
                <h4 class="mt-3">Repayment Terms</h4>
                <p>- Clearly define the repayment terms, including the frequency and method of repayment.</p>
                <p>- Specify the consequences of late or missed payments.</p>
                <h4 class="mt-3">Early Repayment</h4>
                <p>- Address the conditions under which borrowers can make early repayments.</p>
                <p>- Clarify if there are any penalties or benefits associated with early repayment.</p>
                <h4 class="mt-3">Refundable Amounts</h4>
                <p>- Specify whether any portion of the interest or fees is refundable in certain situations (e.g., overpayment, early repayment)</p>
                <h4 class="mt-3">Default and Collection</h4>
                <p>- Outline the procedures and consequences in case of default.</p>
                <p>- Clarify the company's collection policies and any associated costs.</p>
                <h4 class="mt-3">Cancellation and Rescheduling</h4>
                <p>- Specify the conditions under which a loan can be canceled or rescheduled.</p>
                <p>- Outline any fees or penalties associated with cancellation or rescheduling.</p>
                <h4 class="mt-3">Customer Communication</h4>
                <p>- Clearly communicate the refund policy to customers during the loan application process.</p>
                <p>- Provide written documentation of the refund policy to borrowers.</p>
                <h4 class="mt-3">Compliance with Regulations</h4>
                <p>- Ensure that the refund policy aligns with relevant financial regulations and laws in the jurisdiction where the microfinance company operates.</p>
                <h4 class="mt-3">Customer Support</h4>
                <p>- Provide contact information for customer support to address any questions or concerns related to the refund policy.</p>
                <h4 class="mt-3">Flexibility and Compassion</h4>
                <p>- Consider incorporating flexibility and compassion in the policy, particularly for unforeseen circumstances that may affect a borrower's ability to repay.</p>
            </div>
        </div>
        <Footer class="margin-zero" />
    </div>
</template>

<script>
// import Topbar from '../Layouts/Topbar'
import Navbar from '../Layouts/Navbar'
import PageBanner from '../Common/PageBanner'
import Footer from '../Layouts/Footer'

export default {
    name: 'AboutPageOne',
    components: {
        // Topbar,
        Navbar,
        PageBanner,
        Footer,
    }
}
</script>