<template>
    <div>
        <div :class="['navbar-area', { 'is-sticky': isSticky }]">
            <div class="main-navbar navbar-with-white-color">
                <div class="container-fluid">
                    <nav class="navbar navbar-expand-lg navbar-light">
                        <router-link class="navbar-brand" to="/">
                            <img src="../../assets/images/logo.png" alt="image"  height="100" width="260">
                        </router-link>

                        <div
                            class="navbar-toggler"
                            @click="active = !active"
                            :aria-pressed="active ? 'true' : 'false'"
                            v-bind:class="{ active: button_active_state }"
                            v-on:click="button_active_state = !button_active_state"
                        >
                            <i class="ri-menu-line"></i>
                            <i class="ri-close-line"></i>
                        </div>

                        <div class="collapse navbar-collapse" :class="{ toggler: active }">
                            <ul class="navbar-nav ms-auto">
                                <li class="nav-item">
                                    <router-link to="/" class="nav-link">
                                        Home
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link to="/about-us" class="nav-link">
                                        About Us
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <a href="#" class="nav-link">
                                        Products 
                                        <i class="ri-arrow-down-s-line"></i>
                                    </a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item">
                                            <router-link to="" class="nav-link">
                                                Products
                                            </router-link>
                                        </li>
                                        <li class="nav-item">
                                            <router-link to="" class="nav-link">
                                                EMI Calculator
                                            </router-link>
                                        </li>
                                        <li class="nav-item">
                                            <router-link to="" class="nav-link">
                                                How It Works
                                            </router-link>
                                        </li>
                                        <li class="nav-item">
                                            <router-link to="" class="nav-link">
                                                Apply Loan
                                            </router-link>
                                        </li>
                                    </ul>
                                </li>
                                <li class="nav-item">
                                    <router-link to="" class="nav-link">
                                        Services
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <a href="#" class="nav-link">
                                        Resources 
                                        <i class="ri-arrow-down-s-line"></i>
                                    </a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item">
                                            <router-link to="" class="nav-link">
                                                Client Awareness
                                            </router-link>
                                        </li>
                                        <!-- <li class="nav-item">
                                            <router-link to="/news-articles" class="nav-link">
                                                News and Articles
                                            </router-link>
                                        </li> -->
                                    </ul>
                                </li>
                                <li class="nav-item">
                                    <router-link to="/contact" class="nav-link">
                                        Contact
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <a @click="showProfile" class="nav-link" style="cursor: pointer !important;">Pay EMI</a>  
                                </li>
                                <!-- <li class="nav-item">
                                    <a @click="showEcs" class="nav-link" style="cursor: pointer !important;">E-Mandate</a>  
                                </li> -->
                                <li class="nav-item logout-icon" v-if="show_logout">
                                    <a @click="logout" class="nav-link" style="cursor: pointer !important;" title="Sign Out">Sign Out</a>  
                                </li>
                            </ul>
                        </div>

                        <div class="others-options d-flex align-items-center mx-2">
                            <div class="option-item mr-4 logout-icon-m" v-if="show_logout"> 
                                <a @click="logout" style="cursor: pointer !important;" title="Sign Out" class="d-flex align-items-center justify-content-center"><i class="ri-logout-circle-r-line fs-4"></i></a>
                            </div>
                            <div class="option-item">
                                <div
                                    @click="search = !search"
                                    :aria-pressed="search ? 'true' : 'false'"
                                    v-bind:class="{ search: button_search_state }"
                                    v-on:click="button_search_state = !button_search_state"
                                >
                                    <i class="search-btn ri-search-line"></i>
                                    <i class="close-btn ri-close-line"></i>
                                </div>
                                <div 
                                    class="search-overlay" 
                                    :class="{ show: search }"
                                >
                                    <div class='search-box'>
                                        <form class="search-form">
                                            <input class="search-input" placeholder="Search..." type="text">

                                            <button class="search-button" type="submit">
                                                <i class="ri-search-line"></i>
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.navbar-with-white-color{
    background-color: white;
}
.main-navbar .navbar .navbar-nav .nav-item a{
    color: #23183a !important;
}
.main-navbar .navbar .navbar-nav .nav-item a:hover{
    color: var(--main-color) !important;
}
.main-navbar .navbar .others-options .option-item .search-btn{
    color: #23183a !important;
}
.main-navbar .navbar .others-options .option-item .search-btn:hover{
    color: var(--main-color) !important;
}
/*Tablet Query*/
@media only screen and (min-width: 481px) and (max-width: 768px) {
    .main-navbar .navbar .navbar-nav .nav-item a:after{ 
        bottom: 5px !important;
    }
    .main-navbar .navbar .navbar-nav .nav-item a.active:after, .main-navbar .navbar .navbar-nav .nav-item a:focus:after, .main-navbar .navbar .navbar-nav .nav-item a:hover:after{
        left: 0px !important;
    }
    .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li a:before{
        left: 10px !important;
        top: 27.8px !important;        
        width: 20px !important;
    }
}
/*Mobile Query*/
@media only screen and (max-width: 480px) {
    .main-navbar .navbar .navbar-nav .nav-item a:after{
        bottom: 5px !important;
    }
    .main-navbar .navbar .navbar-nav .nav-item a.active:after, .main-navbar .navbar .navbar-nav .nav-item a:focus:after, .main-navbar .navbar .navbar-nav .nav-item a:hover:after{
        left: 0px !important;
    }
    .main-navbar .navbar .navbar-nav .nav-item .dropdown-menu li a:before{
        left: 10px !important;
        top: 27.8px !important;        
        width: 20px !important;
    }
}
</style>
<script>
export default {
    name: "Navbar",
    data() {
        return {
            isSticky: false,
            search: false,
            button_search_state: false,
            active: false,
            button_active_state: false,
            isSideMenu: false,
            show_logout:false
        };
    },
    mounted() {
        const that = this;
        window.addEventListener("scroll", () => {
            let scrollPos = window.scrollY;
            if (scrollPos >= 100) {
                that.isSticky = true;
            } else {
                that.isSticky = false;
            }
        });
    },
    created(){    
        this.checkSignOut();
    },
    methods: {
        isSideMenuMethod(isSideMenu){
            return this.isSideMenu = !isSideMenu
        },
        showProfile: function(){
            var token = localStorage.getItem('auth_token');
          
            if(!token){             
                this.$router.push({path: ("/customer-login")});        
            }else{                
				this.$router.push({path: ("/payments")});                 
            }
		}, 
        showEcs: function(){
            var token = localStorage.getItem('ecs_auth_token');
          
            if(!token){             
                this.$router.push({path: ("/mandate-login")});        
            }else{                
				this.$router.push({path: ("/ecs-registration")});                 
            }
		}, 
        logout: function(){
            localStorage.removeItem("auth_token");
            localStorage.removeItem("ecs_auth_token");
            this.$router.push({path: ("/")});  
        },
        checkSignOut(){
            var token = localStorage.getItem('auth_token');
            var ecs_token = localStorage.getItem('ecs_auth_token');
            if(token || ecs_token){
                this.show_logout = true;
            }else{
                this.show_logout = false;
            }
        }
    },
    computed: {
        shoppingCart() {
            return this.$store.state.cart;
        },
    },
};
</script>
