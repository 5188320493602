<template>
    <div class="about-area pt-100 pb-75">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="about-wrap-image" data-tilt>
                        <img src="../../assets/images/about/about-5.jpg" alt="image" data-aos="fade-down" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="about-wrap-content" data-aos="fade-up" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                        <span>About Us</span>
                        <h3>Your Trusted Partner For All Financial Needs</h3>
                        <p>
                            Welcome to Urudi, where we believe in empowering dreams and fostering financial inclusion. We are a dedicated microfinance institution committed to positively impacting individuals and communities through accessible financial solutions. We have been at the forefront of promoting financial inclusion, believing that everyone deserves the chance to achieve their financial aspirations regardless of their background. Join us on this impactful journey as we continue to empower dreams and build a more inclusive and prosperous future.
                        </p>  
                    </div>
                </div>
            </div>

            <div class="about-inner-box">
                <div class="row justify-content-center">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-about-card" data-aos="fade-up" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                            <h3>Our Vision</h3>
                            <p>To be India’s leading social bank that offers equal opportunities for the whole society through universal access and financial deepening, thus promoting financial inclusion, livelihood and economic development as a whole</p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="single-about-card" data-aos="fade-down" data-aos-delay="70" data-aos-duration="700" data-aos-once="true">
                            <h3>Our Mission</h3>
                            <p>To provide responsive banking services to the underserved and un-served households in India facilitated by customer-centric products, high quality service and innovative technology.</p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="single-about-card" data-aos="fade-up" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                            <div class="card-image" data-tilt>
                                <img src="../../assets/images/about/about-4.jpg" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="about-circle-shape">
            <img src="../../assets/images/about/about-circle.png" alt="image">
        </div>
    </div>
</template>

<script>
export default {
    name: 'AboutTwo'
}
</script>