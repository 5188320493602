<template>
    <div class="about-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="about-image" data-tilt>
                        <img src="../../assets/images/about/about-2.png" alt="image" data-aos="fade-down" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="about-content" data-aos="fade-up" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                        <span>Welcome</span>
                        <h3>Universal Rural And Urban Development Initiatives</h3>
                        <p>We are a dedicated microfinance institution committed to positively impacting individuals and communities through accessible financial solutions. We have been at the forefront of promoting financial inclusion, believing that everyone deserves the chance to achieve their financial aspirations regardless of their background.</p>
                        <!-- <h3>We Are <b>All In One</b> IT Solution & Technology Company</h3> -->
                        <!-- <p>We are leading technology solutions providing company all over the world doing over 40 years lorem ipsum dolor sit amet.</p>
                        <p>We are leading technology solutions providing company all over the world doing over 40 years. Lorem ipsum dolor sit amet consetetur sadipscing elitr sed diam non umy eirmod tempor invidunt ut labore.</p> -->
                        <div class="about-btn">
                            <router-link to="/about-us" class="default-btn">
                                Know More About Us
                            </router-link> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="about-shape-1">
            <img src="../../assets/images/about/about-shape.png" alt="image">
        </div>
    </div>
</template>

<script>
export default {
    name: 'About'
}
</script>