import { createWebHistory, createRouter } from "vue-router";

//BEGIN New Routes
import HomePage from "../components/Pages/HomePage";
import AboutUs from "../components/Pages/AboutUs";
import Products from "../components/Pages/Products";
import EmiCalculator from "../components/Pages/EMICalculator";
// import ApplyLoan from "../components/Pages/ApplyLoan";
import Services from "../components/Pages/Services";
// import ClientAwareness from "../components/Pages/ClientAwareness";
// import NewsAndArticles from "../components/Pages/NewsAndArticles";
import Contact from "../components/Pages/Contact";
import CustomerLogin from "../components/Pages/CustomerLogin";
import Payments from "../components/Pages/Payments";
import TermsAndConditions from "../components/Pages/TermsAndConditions";
import DisclaimerPolicy from "../components/Pages/DisclaimerPolicy";
import PrivacyPolicy from "../components/Pages/PrivacyPolicy";
import RefundPolicy from "../components/Pages/RefundPolicy";
// import HowItWorks from "../components/Pages/HowItWorks";
import PaymentSuccess from "../components/Pages/PaymentSuccess";
import PaymentFailure from "../components/Pages/PaymentFailure";
import UserAborted from "../components/Pages/UserAborted";
// import ECSRegistration from "../components/Pages/ECSRegistrationPage";
import TestPayments from "../components/Pages/TestPayments";
import TestPaymentSuccess from "../components/Pages/TestPaymentSuccess";
import TestPaymentFailure from "../components/Pages/TestPaymentFailure";
import TestUserAborted from "../components/Pages/TestUserAborted";
//ECS
// import EcsRegSuccess from "../components/Pages/EcsRegSuccess";
// import EcsRegFailure from "../components/Pages/EcsRegFailure";
// import EcsUserAborted from "../components/Pages/EcsUserAborted";

// import MandateLogin from "../components/Pages/MandateLogin";

//END

const routes = [
  { path: "/", name: "HomePage", component: HomePage },
  { path: "/about-us", name: "AboutUs", component: AboutUs },
  { path: "/products", name: "Products", component: Products },
  { path: "/emi-calculator", name: "EmiCalculator", component: EmiCalculator },
  // { path: "/apply-loan", name: "ApplyLoan", component: ApplyLoan },
  { path: "/services", name: "Services", component: Services },
  // { path: "/client-awareness", name: "ClientAwareness", component: ClientAwareness },
  // { path: "/news-articles", name: "NewsAndArticles", component: NewsAndArticles },
  { path: "/contact", name: "Contact", component: Contact },
  { path: "/customer-login", name: "CustomerLogin", component: CustomerLogin },
  { path: "/Payments", name: "Payments", component: Payments },
  { path: "/terms-and-conditions", name: "TermsAndConditions", component: TermsAndConditions },
  { path: "/disclaimer-policy", name: "DisclaimerPolicy", component: DisclaimerPolicy },
  { path: "/privacy-policy", name: "PrivacyPolicy", component: PrivacyPolicy },
  { path: "/refund-policy", name: "RefundPolicy", component: RefundPolicy },
  // { path: "/how-it-works", name: "HowItWorks", component: HowItWorks },
  { path: "/payment-success", name: "PaymentSuccess", component: PaymentSuccess },
  { path: "/payment-failure", name: "PaymentFailure", component: PaymentFailure }, 
  { path: "/user-aborted", name: "UserAborted", component: UserAborted },  
  // { path: "/ecs-registration", name: "ECSRegistration", component: ECSRegistration },  
  { path: "/test-payments", name: "TestPayments", component: TestPayments }, 
  { path: "/test-payment-success", name: "TestPaymentSuccess", component: TestPaymentSuccess },
  { path: "/test-payment-failure", name: "TestPaymentFailure", component: TestPaymentFailure }, 
  { path: "/test-user-aborted", name: "TestUserAborted", component: TestUserAborted },   
  // { path: "/mandate-login", name: "MandateLogin", component: MandateLogin },
  // { path: "/ecs-registration-success", name: "EcsRegSuccess", component: EcsRegSuccess },
  // { path: "/ecs-registration-failure", name: "EcsRegFailure", component: EcsRegFailure }, 
  // { path: "/ecs-user-aborted", name: "EcsUserAborted", component: EcsUserAborted },   
];

const router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: "active",
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
