<template>
    <div class="projects-area pt-100 pb-75">
        <div class="container">
            <div class="section-title">
                <span>Products</span>
                <h2>Loan Products</h2>
            </div>

            <ul class="projects-filter-menu"> 
                <li 
                    class="filter" 
                    :class="{active:itemFilterKey == 'personal_loan'}" 
                    v-on:click="itemFilterKey = 'personal_loan'" 
                >
                    Personal Loan
                </li>
                <li 
                    class="filter" 
                    :class="{active:itemFilterKey == 'home_loan'}"
                    v-on:click="itemFilterKey = 'home_loan'"
                >
                    Home Loan
                </li>
                <li 
                    class="filter" 
                    :class="{active:itemFilterKey == 'group_loan'}"
                    v-on:click="itemFilterKey = 'group_loan'"
                >
                    Group Loan
                </li>
                <li 
                    class="filter" 
                    :class="{active:itemFilterKey == 'vehicle_loan'}"
                    v-on:click="itemFilterKey = 'vehicle_loan'"
                >
                    Vehicle Loan 
                </li>
            </ul>  
            <div class="row justify-content-center">
                <div 
                    class="col-lg-4 col-md-6"
                    v-for="item in itemFilter"
                    :key="item.id"
                >
                    <div class="single-projects-item" data-aos="fade-up" data-aos-delay="50" data-aos-duration="500" data-aos-once="true">
                        <div class="projects-image">
                            <router-link :to="item.btnLink">
                                <img :src="item.image" alt="image">
                            </router-link>
                        </div>
                        <div class="projects-content">
                            <h3>
                                <router-link :to="item.btnLink">
                                    {{item.title}}
                                </router-link>
                            </h3>
                            <router-link :to="item.btnLink" class="projects-btn">
                                {{item.btnText}}
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="projects-bg-shape-1">
            <img src="../../assets/images/projects/projects-bg-shape.png" alt="image">
        </div>
    </div>
</template>

<script>
export default {
    name: 'Projects',
    data: function (){
        return {
            itemFilterKey: 'personal_loan',
            items: [
                {
                    id: 1,
                    image: require('../../assets/images/products/personal-loan.jpg'),
                    title: 'Personal Loan',
                    btnLink: '/',
                    btnText: 'View More',
                    key: 'personal_loan',
                },
                {
                    id: 2,
                    image: require('../../assets/images/products/group-loan.jpg'),
                    title: 'Group Loan',
                    btnLink: '/',
                    btnText: 'View More',
                    key: 'group_loan',
                },
                {
                    id: 3,
                    image: require('../../assets/images/products/vehicle-loan.jpg'),
                    title: 'Vehicle Loan',
                    btnLink: '/',
                    btnText: 'View More',
                    key: 'vehicle_loan',
                },
                {
                    id: 4,
                    image: require('../../assets/images/products/home-loan.jpg'),
                    title: 'Home Loan',
                    btnLink: '/',
                    btnText: 'View More',
                    key: 'home_loan',
                },
            ],
        }
    },
    computed: {
        itemFilter(){
            return this[this.itemFilterKey]
        },
        personal_loan(){
            return this.items.filter((item) => item.key == 'personal_loan')
        },
        home_loan(){
            return this.items.filter((item) => item.key == 'home_loan')
        },
        group_loan(){
            return this.items.filter((item) => item.key == 'group_loan')
        },
        vehicle_loan(){
            return this.items.filter((item) => item.key == 'vehicle_loan')
        }
    }
}
</script>